import {mapActions, mapGetters, mapMutations} from 'vuex';

import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: 'registration',
  mixins: [validationMixin],

  data() {
    return {
      payload: {
        email: '',
        password: '',
        password_confirmation: '',
        name: '',
        phone: '',
        avatar: null,
        utm_medium: '',
        utm_source: '',
        site_url: ''
      },
      isValidPhone: false,
      validationErrors: {
        phone: []
      }
    }
  },
  validations: {
    payload: {
      name: {
        required,
        maxLength: maxLength(50),
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      password_confirmation: {
        maxLength: maxLength(20),
        required,
        sameAsPassword: sameAs('password')
      },
      phone: {
        required,
        minLength: minLength(9)
      },
      avatar: {},
    }
  },
  computed: {
    ...mapGetters({
      loadingRegistration: 'auth/loadingRegistration',
      config: 'setting/config',
      popupRegister: 'auth/popupRegister',
      popupRegisterInfo: 'auth/popupRegisterInfo',
    }),
    disableButton() {
      return this.payload.name === '' ||
        this.payload.email === '' ||
        this.payload.password === '' ||
        this.payload.password_confirmation === '' ||
        !this.isValidPhone;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }

      return error;
    },
    avatarErrors() {
      let error = [];

      return error;
    }
  },
  components: {
  },
  created(){
    this.setUtm();
  },
  methods: {
    ...mapActions({
      registration: `auth/REGISTRATION`,
      fetchUserData: `profile/FETCH_USER_DATA`,
    }),

    ...mapMutations({
      showSendConfirmRegistrationPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
      setDataToContentPopup: `system/POPUP_TEXT_CONTENT`,
      changePopupRegister: `auth/CHANGE_POPUP_REGISTER`,
      changePopupRegisterInfo: `auth/CHANGE_POPUP_REGISTER_INFO`,
      changePopupSignIn: `auth/CHANGE_POPUP_SIGN_IN`,
      changeContentPopup: `system/POPUP_TEXT_CONTENT`,
      changeShowContentPopup: `system/SHOW_POPUP_TEXT_CONTENT`,
      setToken: `auth/SET_TOKEN`,
    }),
    changeLogin(){
      this.changePopupRegister(false);
      setTimeout(()=>{
        this.changePopupSignIn(true);
      },150)
    },
    getConfigItem(key) {
      for (let i in this.config) {
        if (this.config[i].key === key) {
          return this.config[i];
        }
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let registerData = {};
        registerData.first_name = this.payload.name;
        registerData.phone = this.payload.phone.replace(/[\s.,%()-]/g, '');
        registerData.email = this.payload.email;
        registerData.password = this.payload.password;
        registerData.password_confirmation = this.payload.password_confirmation;
        registerData.avatar = this.payload.avatar;
        if( this.popupRegisterInfo.course ){
          registerData.course = this.popupRegisterInfo.course;
        }
        if( this.payload.utm_medium ){
          registerData.utm_medium = this.payload.utm_medium;
          registerData.utm_source = this.payload.utm_source;
          registerData.site_url = this.payload.site_url;
        }

        this.registration(registerData)
          .then((response) => {


            this.setToken(response.data.access_token)

            this.$toasted.success(this.$t('successUserRegister'));
            this.changePopupRegister(false);
            this.$nextTick(()=> {
              this.fetchUserData();
            })

          }).catch(error => {
            let formattedMessage = null;

            if (error && error.response) {
              if (error.response.data.message) {
                formattedMessage = error.response.data.message;
              }

              if (error.response.data.error) {
                formattedMessage = error.response.data.error.message;
              }

              if (error.response.data.errors) {
                Object.keys(error.response.data.errors).forEach(errorKey => {
                  error.response.data.errors[errorKey].forEach((line) => {
                    formattedMessage += `\r\n${line}`;
                  });
                });
              }
            }

            formattedMessage && this.$toasted.error(formattedMessage);
          });
      }
    },
    changePhoneInput(number, info) {
      this.isValidPhone = info.valid;
      if (this.isValidPhone) {
        this.payload.phone = info.number.e164.replace('+', '');
        this.validationErrors.phone = [];
      }
    },
    changeValidationPhone() {
      this.validationErrors.phone = [];
      if (this.payload.phone.length > 0) {
        if (!this.isValidPhone) {
          this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
        } else {
          this.validationErrors.phone = [];
        }
      } else {
        this.validationErrors.phone.push(this.$t('enterCorrectPhone'))
      }
    },
    setUtm() {
      this.payload.utm_medium = this.$route.query.utm_medium;
      this.payload.utm_source = this.$route.query.utm_source;
      this.payload.site_url= window.location.origin+window.location.pathname;
    }
  },
}
